<template>
  <form class="modal-content animate" @submit.prevent="$emit('submit')">
    <div class="imgcontainer" v-if="showPhoneNumber">
      <h1 class="red">Enter Phone number</h1>
    </div>

    <div class="container" v-if="showPhoneNumber">
      <label for="uname"><b>Phone Number</b></label>
      <input
        v-model="credentials.phoneNumber"
        required
        type="text"
        placeholder="Phone Number"
      />
    </div>

    <div class="container" v-if="isSmsVerified">
      <input
        required
        type="text"
        v-model="credentials.verificationCode"
        placeholder="Enter Verification Code"
      />
    </div>
    <div>
      <button type="submit">
        {{ isLoading ? "Verifying...." : "Verify" }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "MutiFactorForm",
  props: {
    isSmsVerified: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    showPhoneNumber: { type: Boolean, default: false },
    credentials: {
      type: Object,
      default: () => ({
        phoneNumber: "",
        verificationCode: "",
      }),
    },
  },
};
</script>

<style></style>
