<template>
  <div :class="`alert alert-${type}`" role="alert">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "", required: true },
    type: { type: String, default: "success" },
  },
};
</script>

<style></style>
