import firebase from "firebase/app";

/**
 * Firebase utilty mixin to used to fetch current user details
 * @author {Jatin Kamboj}
 */
export default {
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      firebaseUser: null,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Persists firebase user in the browser tab
     */
    async persistFirebaseUser() {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    },
    /**
     * fecthes details of current logged in firebase user
     */
    fetchCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
          unsubscribe();
          try {
            if (user) {
              this.firebaseUser = user;
              resolve(user);
            } else {
              console.log("User Logged out successfully ...");
            }
          } catch (error) {
            reject(error);
          }
        }, reject);
      });
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    if (!firebase.auth().currentUser) {
      await this.fetchCurrentUser();
    } else {
      this.firebaseUser = firebase.auth().currentUser;
    }
  },
};
