<template>
  <div class="container">
    <h1>Welcome to dashboard {{ userDetails && userDetails.email }}</h1>
    <div class="user-profile__details">
      <div class="d-flex align-content-center justify-content-center">
        <div class="card mt-4" style="width: 58rem;">
          <ul class="list-group list-group-flush">
            <li class="list-group-item py-3">
              <div class="row">
                <div class="col fw-bold">Email Verified</div>
                <div class="col d-flex justify-content-center">
                  <div class="mx-5">
                    <span>{{ isEmaiVerified ? "Yes" : "No" }}</span>
                  </div>
                  <div
                    class="mx-5"
                    :class="{ 'cursor-not-allowed': isEmaiVerified }"
                  >
                    <toggle-button
                      :sync="true"
                      :disabled="isEmaiVerified"
                      v-model="sendVerificationEmail"
                      @change="onSendVerificationEmail"
                    />
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item py-3">
              <div class="row">
                <div class="col fw-bold">Multi Factor</div>
                <div class="col d-flex justify-content-center">
                  <div class="mx-4">
                    {{ isMultiFactorEnabled ? "Enabled" : "Disabled" }}
                  </div>
                  <div class="enable-toogle">
                    <label for="" class="mx-2">
                      {{ isMultiFactorEnabled ? "Disable" : "Enable" }} multi
                      factor
                    </label>
                    <toggle-button
                      :sync="true"
                      @change="onDisableMultiFactor"
                      v-model="activateMutiFactor"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <br />
      </div>
      <div
        v-if="activateMutiFactor && !isMultiFactorEnabled"
        class="d-flex align-content-center justify-content-center"
      >
        <div class="card mt-4" style="width: 58rem;">
          <enroll-multi-factor @success="onSuccess" />
        </div>
      </div>
      <div class="mt-4">
        <alert-message v-if="!!successMessage" :text="successMessage" />
      </div>

      <alert-message v-if="!!errorMessage" :text="errorMessage" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/utils";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import firebase from "firebase/app";
import { ToggleButton } from "vue-js-toggle-button";
import firebaseMixin from "@/mixins/firebase.mixin.js";
import EnrollMultiFactor from "@/components/EnrollMultiFactor.vue";

/**
 * Dasbhoard page
 */
export default {
  name: "Dashboard",
  /**
  |--------------------------------------------------
  | Mixins
  |--------------------------------------------------
  */
  mixins: [firebaseMixin],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { EnrollMultiFactor, AlertMessage, ToggleButton },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      activateMutiFactor: false,
      sendVerificationEmail: false,
      phoneNumber: "",
      successMessage: "",
      errorMessage: "",
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    onDisableMultiFactor({ value }) {
      if (!value && typeof value === "boolean") {
        this.disableMultiFactor();
      }
    },
    /**
     * Maps mutations in component
     */
    ...mapMutations({
      showLoader: "auth/SHOW_LOADER",
      setCurrentUser: "auth/SET_CURRENT_USER",
      updateMultiFactors: "auth/SET_MULTI_FATCOR_DETAILS",
    }),
    /**
     * Handles multi factor success message
     */
    onSuccess(phoneNumber) {
      this.phoneNumber = phoneNumber;
      this.successMessage = `Multi factor is activated on your ${phoneNumber} number`;
    },
    /**
     * Sends email verification email to user email address
     */
    async onSendVerificationEmail(value) {
      try {
        if (value && !this.isEmaiVerified) {
          this.showLoader(true);

          const actions = { url: window.location.href };
          await firebase.auth().currentUser.sendEmailVerification(actions);

          this.successMessage = `Email verification email have been sent to you email.`;
        }
      } catch (error) {
        this.errorMessage = error?.message;
      } finally {
        this.showLoader(false);
      }
    },
    /**
     * Returns list of multi factor options of a current user
     * @returns {Array} List of multi factor options
     */
    getUserMultiFactors() {
      return this.firebaseUser.multiFactor.enrolledFactors;
    },
    /**
     * Disable multi factor for a user
     */
    async disableMultiFactor() {
      if (!this.isMultiFactorEnabled) return;
      try {
        this.showLoader(true);
        // Returns a list of the user's enrolled second factors.
        const authOptions = this.getUserMultiFactors();
        const [defaultOption] = authOptions;

        await this.firebaseUser.multiFactor.unenroll(defaultOption);
        await this.updateMultiFactors(this.getUserMultiFactors());

        // Show success message to the user
        this.successMessage = `User successfully unenrolled multi factor factor`;
      } catch (error) {
        this.errorMessage = error?.message ?? "Something went wrong";
      } finally {
        this.showLoader(false);
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      userDetails: "auth/userDetails",
      multiFactors: "auth/multiFactors",
    }),
    isEmaiVerified() {
      return this.userDetails?.emailVerified ?? false;
    },
    isMultiFactorEnabled() {
      return !isEmpty(this.multiFactors) ? !!this.multiFactors[0] : false;
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    this.activateMutiFactor = this.isMultiFactorEnabled;
    this.sendVerificationEmail = this.isEmaiVerified;

    if (!this.isEmaiVerified) {
      const user = await this.fetchCurrentUser();
      const emailVerified = user?.emailVerified ?? false;

      this.setCurrentUser({ ...this.userDetails, emailVerified });
    }
  },
};
</script>
