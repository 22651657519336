<template>
  <div>
    <muti-factor-form
      @submit="enrollMultiFactor"
      :isSmsVerified="!!verificationId"
      :isLoading="isLoading"
      :credentials="credentials"
      showPhoneNumber
    />
    <div id="recaptcha-container" />
    <alert-message
      v-if="!!verificationId"
      :text="`Sms code have been sent your number ${credentials.phoneNumber}`"
    />
    <alert-message v-if="!!errorMessage" :text="errorMessage" type="danger" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapMutations, mapActions } from "vuex";
import MutiFactorForm from "@/components/MutiFactorForm.vue";

import AlertMessage from "@/components/shared/AlertMessage.vue";

/**
 * Enrolls an existing user with Multi factor authentication
 */
export default {
  name: "EnrollMultiFactor",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { MutiFactorForm, AlertMessage },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      verificationId: null,
      credentials: {},
      isLoading: false,
      errorMessage: "",
      recaptchaVerifier: null,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Maps mutations in component
     */
    ...mapMutations({
      showLoader: "auth/SHOW_LOADER",
    }),
    ...mapActions({
      getCurrentUserMultiFactor: "auth/getCurrentUserMultiFactor",
    }),
    setIsLoading(val) {
      this.isLoading = val;
      this.showLoader(val);
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        if (this.verificationId) return this.verifySmsCode();
        this.setIsLoading(true);

        const multiFactorSession = await firebase
          .auth()
          .currentUser.multiFactor.getSession();
        // Specify the phone number and pass the MFA session.
        var phoneInfoOptions = {
          phoneNumber: this.credentials.phoneNumber,
          session: multiFactorSession,
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier
        );
      } catch (error) {
        this.errorMessage = error?.message ?? "Something went wrong";
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verofy sms code of the user
     */
    async verifySmsCode() {
      try {
        this.showLoader(true);
        var cred = firebase.auth.PhoneAuthProvider.credential(
          this.verificationId,
          this.credentials.verificationCode
        );
        var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );
        await firebase
          .auth()
          .currentUser.multiFactor.enroll(multiFactorAssertion, "");
        this.getCurrentUserMultiFactor();
        this.$emit("success", this.credentials.phoneNumber);
      } catch (error) {
        this.errorMessage = error?.message ?? "Something went wrong";
      } finally {
        this.showLoader(false);
      }
    },
    /**
     * Verifies and displayes recaptcha
     */
    verifyRecaptchaVerifier() {
      this.setIsLoading(true);

      const CAPTACHA_CONTAINER_ID = "recaptcha-container";
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        CAPTACHA_CONTAINER_ID,
        {
          size: "invisible",
          callback: function(response) {
            // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
            this.recaptchaVerified = !!response;
          },
        }
      );

      this.recaptchaVerifier.render();
      this.setIsLoading(false);
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.verifyRecaptchaVerifier();
  },
};
</script>
